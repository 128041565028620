import PostRenderer from './PostRenderer'
import Preloader from './Layout/Preloader'
import PropTypes from 'prop-types'
import { React, useState, useEffect } from 'react'
import Masonry from 'react-masonry-css'

function * chunks (arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n)
  }
}

const MasonryMobile = ({ data }) => {
  const renderItems = posts => {
    return posts.map((post, index) => {
      return (
        <div key={index.toString()} className='relative grid-item grid-item--mobile sml'>
          {/* {post.title} */}
          <PostRenderer
            index={index}
            key={index}
            post={post}
            onVisibilityChange={() => {}}
          />
        </div>
      )
    })
  }

  if (!data || !data.posts) {
    return <Preloader isLoading message='Loading...' />
  }

  const { posts } = data

  // Split posts array into arrays of 10 items.
  const postBatches = [...chunks(posts, 10)]

  const grids = postBatches.map((batch) => {
    return batch.map((post, index) => {
      // if (index === 0) {
      //   return null
      // }
      return (
        <div key={index.toString()} className='relative grid-item grid-item--mobile sml'>
          <PostRenderer
            index={index}
            key={index}
            post={post}
            onVisibilityChange={() => {}}
          />
        </div>
      )
    })
  })

  function firstPost () {
    const post = postBatches[0][0]
    console.log('post', post)
    return (
      <div key='0' className='relative mb-2 grid-item grid-item--mobile sml'>
        <PostRenderer
          index={0}
          key={0}
          post={post}
          onVisibilityChange={() => {}}
        />
      </div>
    )
  }

  // TWO COL MASONRY
  // return (
  //   <>
  //     {firstPost()}
  //     <Masonry
  //       breakpointCols={{ default: 2 }}
  //       className='mobile-masonry-grid'
  //       columnClassName='mobile-masonry-grid_column'
  //     >
  //       {grids}
  //     </Masonry>
  //   </>
  // )

  // ONE COL, NO MASONRY
  return (
    <div className='flex flex-col space-y-2'>
      {grids}
    </div>
  )
}

MasonryMobile.propTypes = {
  isAuthenticated: PropTypes.bool,

  fetchOnLoadOnly: PropTypes.bool,

  // A JSON-formatted filter for passing through to
  // the document loading process. Passed from pages such as
  // 'category' and 'tag' pages.
  filter: PropTypes.object,

  // Array of items loaded so far.
  data: PropTypes.object,

  // Callback function responsible for loading the next page of items.
  // loadNextPage: PropTypes.func,

  onFetch: PropTypes.func,

  onJoin: PropTypes.func,

  onVisibilityChange: PropTypes.func
}

export default MasonryMobile
