import { Responsive, WidthProvider } from 'react-grid-layout'
import PostRenderer from './PostRenderer'
import Preloader from './Layout/Preloader'
import PropTypes from 'prop-types'
import { React, useState, useEffect } from 'react'
// import scrollIntoView from 'scroll-into-view'

const ResponsiveGridLayout = WidthProvider(Responsive)

const layouts = {
  lg: [
    { i: '0', x: 0, y: 0, w: 4, h: 2, static: true, className: 'lge' },
    { i: '1', x: 4, y: 0, w: 2, h: 1, static: true, className: 'sml' },
    { i: '2', x: 4, y: 1, w: 2, h: 1, static: true, className: 'sml' },
    { i: '3', x: 0, y: 2, w: 2, h: 2, static: true, className: 'med' },
    { i: '4', x: 4, y: 2, w: 4, h: 2, static: true, className: 'lge' },
    { i: '5', x: 0, y: 4, w: 4, h: 2, static: true, className: 'lge' },
    { i: '6', x: 4, y: 4, w: 2, h: 2, static: true, className: 'med' },
    { i: '7', x: 0, y: 6, w: 2, h: 1, static: true, className: 'sml' },
    { i: '8', x: 4, y: 6, w: 4, h: 2, static: true, className: 'lge' },
    { i: '9', x: 0, y: 7, w: 2, h: 1, static: true, className: 'sml' }
  ],
  md: [
    { i: '0', x: 0, y: 0, w: 6, h: 2, static: true, className: 'lge' },
    { i: '1', x: 0, y: 2, w: 3, h: 2, static: true, className: 'med' },
    { i: '2', x: 3, y: 2, w: 3, h: 1, static: true, className: 'sml' },
    { i: '3', x: 3, y: 3, w: 3, h: 1, static: true, className: 'sml' },
    { i: '4', x: 3, y: 4, w: 6, h: 2, static: true, className: 'lge' },
    { i: '5', x: 0, y: 6, w: 3, h: 2, static: true, className: 'med' },
    { i: '6', x: 3, y: 6, w: 3, h: 2, static: true, className: 'med' },
    { i: '7', x: 0, y: 8, w: 3, h: 1, static: true, className: 'sml' },
    { i: '8', x: 3, y: 8, w: 3, h: 2, static: true, className: 'med' },
    { i: '9', x: 0, y: 9, w: 3, h: 1, static: true, className: 'sml' }
  ],
  sm: [
    { i: '0', x: 0, y: 0, w: 6, h: 1.5, static: true, className: 'lge' },
    { i: '1', x: 0, y: 1.5, w: 3, h: 1.5, static: true, className: 'med' },
    { i: '2', x: 3, y: 1.5, w: 3, h: 0.75, static: true, className: 'sml' },
    { i: '3', x: 3, y: 2.25, w: 3, h: 0.75, static: true, className: 'sml' },
    { i: '4', x: 3, y: 3, w: 6, h: 1.5, static: true, className: 'lge' },
    { i: '5', x: 0, y: 4.5, w: 3, h: 1.5, static: true, className: 'med' },
    { i: '6', x: 3, y: 4.5, w: 3, h: 1.5, static: true, className: 'med' },
    { i: '7', x: 0, y: 6, w: 3, h: 0.75, static: true, className: 'sml' },
    { i: '8', x: 3, y: 6, w: 3, h: 1.5, static: true, className: 'med' },
    { i: '9', x: 0, y: 6.75, w: 3, h: 0.75, static: true, className: 'sml' }
  ]
}

function * chunks (arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n)
  }
}

const MasonryDesktop = ({ data }) => {
  const [gridMargin, setGridMargin] = useState([16, 16])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 1024) {
        setGridMargin([8, 8])
      }
    }
  }, [])
  const [breakpoint, setBreakpoint] = useState('lg')
  const renderItems = posts => {
    return posts.map((post, index) => {
      const cssClass = layouts[breakpoint][index % 10].className || '123'
      const cssClasses = [cssClass, 'grid-item']
      return (
        <div key={index.toString()} data-index={index} className={cssClasses.join(' ')}>
          {/* {post.title} */}
          <PostRenderer
            index={index}
            key={index}
            post={post}
            onVisibilityChange={() => {}}
          />
        </div>
      )
    })
  }

  if (!data || !data.posts) {
    return <Preloader isLoading message='Loading...' />
  }

  const { posts } = data

  // Split posts array into arrays of 10 items.
  const postBatches = [...chunks(posts, 10)]

  const grids = postBatches.map((batch, index) => {
    return (
      <ResponsiveGridLayout
        key={index}
        className='block w-full mb-4 layout'
        layouts={layouts}
        rowHeight={280}
        verticalCompact
        margin={gridMargin}
        containerPadding={[0, 0]}
        breakpoints={{ lg: 1024, md: 768, sm: 640 }}
        cols={{ lg: 6, md: 6, sm: 6 }}
        isDraggable={false}
        isResizable={false}
        useCSSTransforms={false}
        onBreakpointChange={breakpoint => { setBreakpoint(breakpoint) }}
      >
        {renderItems(batch)}
      </ResponsiveGridLayout>
    )
  })

  return (
    <>
      {grids}
    </>
  )
}

MasonryDesktop.propTypes = {
  isAuthenticated: PropTypes.bool,

  fetchOnLoadOnly: PropTypes.bool,

  // A JSON-formatted filter for passing through to
  // the document loading process. Passed from pages such as
  // 'category' and 'tag' pages.
  filter: PropTypes.object,

  // Array of items loaded so far.
  data: PropTypes.object,

  // Callback function responsible for loading the next page of items.
  // loadNextPage: PropTypes.func,

  onFetch: PropTypes.func,

  onJoin: PropTypes.func,

  onVisibilityChange: PropTypes.func
}

export default MasonryDesktop
