import React from 'react'

const Preloader = ({ isFullScreen, isLoading, message }) => {
  let Wrapper = ({ children }) => (
    <div className={`${isLoading ? 'opacity-100 bottom-20' : 'opacity-0 -bottom-10'} transition-all duration-200 ease-in fixed left-0 z-20 w-full`}>
      <div className='container flex'>
        {children}
      </div>
    </div>
  )

  if (isFullScreen) {
    Wrapper = ({ children }) => (
      <div className='fixed top-0 left-0 z-50 block w-full h-full bg-themeBg bg-opacity-95'>
        <div className='relative flex flex-col items-center mx-auto my-0 space-y-2 top-1/2 animate-pulse' style={{ width: '100px' }}>
          {/* {children} */}
          <img className='animate-spin' src='/images/scramblers-s.svg' width='50' />
          <div className='flex items-center justify-start flex-shrink w-auto pt-3 text-white uppercase text-navigation'>
            {message}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Wrapper>
      <div className='flex items-center justify-start flex-shrink w-auto px-3 py-2 text-white bg-black rounded-full shadow-lg text-navigation'>
        <svg className='w-4 h-4 mr-2 animate-spin' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
          <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
          <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z' />
        </svg>
        {message}
      </div>
    </Wrapper>
  )
}

export default Preloader
