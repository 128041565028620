import PropTypes from 'prop-types'
import { React } from 'react'
import MasonryDesktop from './MasonryDesktop'
import MasonryMobile from './MasonryMobile'

const Masonry = ({ data }) => {
  let LayoutComponent = MasonryDesktop
  if (window.innerWidth < 640) {
    LayoutComponent = MasonryMobile
  }
  return (
    <LayoutComponent data={data} />
  )
}

Masonry.propTypes = {
  isAuthenticated: PropTypes.bool,

  fetchOnLoadOnly: PropTypes.bool,

  // A JSON-formatted filter for passing through to
  // the document loading process. Passed from pages such as
  // 'category' and 'tag' pages.
  filter: PropTypes.object,

  // Array of items loaded so far.
  data: PropTypes.object,

  // Callback function responsible for loading the next page of items.
  // loadNextPage: PropTypes.func,

  onFetch: PropTypes.func,

  onJoin: PropTypes.func,

  onVisibilityChange: PropTypes.func
}

export default Masonry
