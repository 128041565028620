// import PropTypes from 'prop-types'
import Post from './Post'
import React, { Component } from 'react'
import scrollIntoView from 'scroll-into-view'
import SubscribeCTA from './Layout/SubscribeCTA'
import { Waypoint } from 'react-waypoint'

class PostRenderer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      articleExpanded: false,
      // currentPosition: '',
      // previousPosition: '',
      visible: false
    }

    this.handlePositionChange = this.handlePositionChange.bind(this)
    this.handlePostWaypointEnter = this.handlePostWaypointEnter.bind(this)
    this.handlePostWaypointLeave = this.handlePostWaypointLeave.bind(this)
    this.handleTopWaypointEnter = this.handleTopWaypointEnter.bind(this)
    this.handleTopWaypointLeave = this.handleTopWaypointLeave.bind(this)
    this.handleToggleArticle = this.handleToggleArticle.bind(this)
  }

  handlePositionChange (obj) {
    // const { index, onVisibilityChange, post } = this.props
    // console.log(' *** handlePositionChange *** :>> ', post.slug, obj)

    // if (obj.currentPosition === 'above' && obj.previousPosition === 'inside') {
    //   this.setState({
    //     // visible: false
    //   })
    // }
  }

  handlePostWaypointEnter (obj) {
    this.setState({
      visible: true
    })
  }

  handlePostWaypointLeave (obj) {
    this.setState({
      visible: false
    })
  }

  handleTopWaypointEnter (obj) {
    const { index, onVisibilityChange, post } = this.props

    // console.log(' *** ENTER *** :>> ', post.slug, obj)

    if (obj.currentPosition === 'inside' && obj.previousPosition === 'above') {
      onVisibilityChange(post, index)
    }
  }

  handleTopWaypointLeave (obj) {
    const { index, onVisibilityChange, post } = this.props

    // console.log('*** LEAVE *** :>> ', post.slug, obj)

    if (obj.currentPosition === 'above' && obj.previousPosition === 'inside') {
      onVisibilityChange(post, index)
    }
  }

  handleToggleArticle (event) {
    const { post } = this.props
    const { articleExpanded } = this.state

    this.setState({
      articleExpanded: !articleExpanded
    })

    document.body.querySelector(`.post[data-id='${post._id}']`).classList.toggle('expanded')

    // blur the 'expand article' button so chrome doesn't scroll with the button
    event.target.blur()

    if (this.state.articleExpanded) {
      const postId = document.querySelector(`.post[data-id='${post._id}']`)

      scrollIntoView(postId, {
        time: 500,
        align: {
          top: 0,
          topOffset: 80
        }
      })
    }
  }

  render () {
    const { index, post } = this.props
    const primaryTag = post.tags && post.tags.find(t => t.primary)

    let postType = ''
    if (primaryTag && ['read'].includes(primaryTag.value)) {
      postType = 'article'
    }
    if (primaryTag && ['promo'].includes(primaryTag.value)) {
      postType = 'customPromo'
    }

    // Create article expander control.
    let articleExpander

    if (postType === 'article') {
      articleExpander = (
        <div className='container expand-article'>
          <div
            className='pt-6 border-gray-400 md:pt-10 default-grid-cols'
            style={{ boxShadow: 'rgb(0 0 0) 0px -11px 15px -20px' }}
          >
            {/* read more for subscriber only */}
            <button className='button' onClick={this.handleToggleArticle}>
              {this.state.articleExpanded ? 'Close article' : 'Read full article'}
            </button>

            {/* if not subscriber show this message */}
            <div className='hidden'>
              <SubscribeCTA
                title='Members only article'
                message='This article is available to subscribers only. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
                cta='Join Now'
              />
            </div>
          </div>
        </div>
      )
    }

    // const headerHeight = document.querySelector('header').clientHeight
    // const footer = document.querySelector('footer')
    // const windowHeight = window.innerHeight
    // let footerHeight = 30
    // footer ? footerHeight = footer.clientHeight : footerHeight = 30
    // const postHeight = (windowHeight - headerHeight) - footerHeight

    return post
      ? (
        <>
          <Waypoint
            onEnter={this.handleTopWaypointEnter}
            onLeave={this.handleTopWaypointLeave}
            onPositionChange={this.handlePositionChange}
            scrollableAncestor='window'
            topOffset='70px'
          // bottomOffset='200px'
          />
          <Waypoint
            onEnter={this.handlePostWaypointEnter}
            onLeave={this.handlePositionChange}
            onPositionChange={this.handlePositionChange}
            scrollableAncestor='window'
            topOffset='200px'
            bottomOffset='20%'
          >
            <div className='w-full h-full'>
              <Post
                key={index}
                index={index}
                isAuthenticated={this.props.isAuthenticated}
                post={post}
                onJoin={this.props.onJoin}
              />
            </div>
          </Waypoint>
        </>
        )
      : (
        <div>Loading...</div>
        )
  }
}

export default PostRenderer
